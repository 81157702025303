import React from 'react'
import { Link } from 'react-router-dom';
import { useWizard } from 'rhf-wizard';
import HomeSearch from '../../../components/HomeSearch';

export default function Navigation() {
    const { goToStep, activeStep } = useWizard();
    const { id } = activeStep;

    return (
        <>
        <div className='wizard__btns'>
            <div className='grid'>
                <Link to='/package-details/1'>
                    <div className="number_count first active">01</div>
                </Link>
                <h6>Tour Package</h6>
            </div>
            <div className='grid'>
                <div
                    className={`number_count ${id == 0 || id == 1 || id == 2 ? 'active' : ''}`}
                    onClick={() => goToStep(0)}
                >
                    02
                </div>
                <h6>Select Flight</h6>
            </div>
            <div className='grid'>
                <div
                    className={`number_count ${id == 1 || id == 2 ? 'active' : ''}`}
                    onClick={() => goToStep(1)}
                >
                    03
                </div>
                <h6>Review</h6>
            </div>
            <div className='grid'>
                <div
                    className={`number_count last ${id == 2 ? 'active' : ''}`}
                    onClick={() => goToStep(2)}
                >
                    04
                </div>
                <h6>Payment</h6>
            </div>
        </div>
        <div className="container">
            <div className="booking_search">
                {
                    id != 2 && <HomeSearch btnTitle="MODIFY"/>
                }
           
            </div>
        </div>
        
        </>
    );
}
