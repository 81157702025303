import React from 'react'
import Title from '../components/Title'

export default function Policy() {
    return (
        <>
            <div className='skyward__policy'>
                <img src="assets/img/policy.png" className='w-100' alt="" />
                <div className="detailed__description">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-9">
                        <Title title={'CANCELLATION POLICY'} color={'var(--primary-color)'} />
                    <h5>"We understand that plans can change. Our cancellation policy is designed to be fair and transparent, providing you with flexibility while ensuring we can manage our operations effectively." Cancellation Terms:</h5>
                    <div className="content">
                        <h5>Token Payment:</h5>
                        <p> The token payment of USD 300 is non-refundable.</p>
                        <p> It secures your spot and initiates the personalization process, which incurs costs on our end.</p>
                        <h5>Final Payment:</h5>
                        <p>Cancellation before [X] days prior to travel: Full refund, minus the token payment.</p>
                        <p>Cancellation between [Y] and [X] days prior to travel: [Z]% refund of the final payment.</p>
                        <p>Cancellation within [Y] days of travel: No refund.</p>
                        <h5>Exceptions:</h5>
                        <p>Certain bookings, such as flights or special events, may have specific cancellation policies. These will be clearly communicated at the time of booking.</p>
                        <p>In case of unforeseen circumstances or force majeure events, we will work with you to find the best possible solution.</p>
                        <h5>Cancellation Process:</h5>
                        <p>To cancel your booking, please contact our customer support team at [contact information].</p>
                        <p>Refunds will be processed within [X] business days of cancellation.</p>
                    </div>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        </>
    )
}
