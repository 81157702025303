import React from 'react'

export default function PackageExpect() {
  return (
    <div className='stress__free'>
      <div className="container">
        <h4>WHAT TO EXPECT</h4>
        <div className="row align-items-center">
          <div className="col-md-8">
            <div className="features">
              <div className="gridItem centerAlign">
                <img src="/assets/img/icons/expect1.svg" alt="" />
                <h5>Personalized Planning</h5>
                <p>We'll Contact You After Your Token Payment To Create Your Ideal Itinerary.</p>
              </div>
              <div className="gridItem centerAlign">
                <img src="/assets/img/icons/expect2.svg" alt="" />
                <h5>Mobile App Access</h5>
                <p>Manage All Your Travel Documents In One Place (Upon Confirmation)</p>
              </div>
              <div className="gridItem lists">
                <ul>
                    <li><img src="/assets/img/icons/expect3.svg" alt="" /> Premier Hotel Stays including Breakfasts</li>
                    <li><img src="/assets/img/icons/expect4.svg" alt="" /> Car-Rentals</li>
                    <li><img src="/assets/img/icons/expect5.svg" alt="" /> Entertainment activities including spa</li>
                    <li><img src="/assets/img/icons/expect6.svg" alt="" /> Car & Travel Insurance</li>
                    <li><img src="/assets/img/icons/expect7.svg" alt="" /> Live support during Travel</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="search_flight">
              <h4>Ready For An Unforgettable Iceland Adventure?</h4>
              <p>Secure Your Spot With A Token Payment Today!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
