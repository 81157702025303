import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import Select from 'react-select'
import './HomeSearch.scss'
import { Link } from 'react-router-dom';


const CounterItem = ({ title, ageRange }) => {
    const [count, setCount] = useState(0);

    const handleAdd = () => {
        setCount(count + 1);
    };

    const handleSubtract = () => {
        if (count > 0) {
            setCount(count - 1);
        }
    };

    return (
        <div className="Item">
            <div className="category">
                <h5>{title}</h5>
                <p>{ageRange}</p>
            </div>
            <div className="add-btn">
                <button onClick={handleSubtract} disabled={count === 0}>
                    <img src="/assets/img/icons/subtract-minus.svg" alt="Subtract" />
                </button>
                <span>{count}</span>
                <button onClick={handleAdd}>
                    <img src="/assets/img/icons/add-plus.svg" alt="Add" />
                </button>
            </div>
        </div>
    );
}


export default function HomeSearch({ btnTitle }) {
    const filterGridRef = useRef(null);
    const [filter, setFilter] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const options = [
        { value: 'Option One', label: 'Option One' },
        { value: 'Option Two', label: 'Option Two' },
        { value: 'Option Three', label: 'Option Three' }
    ]
    const items = [
        { title: 'Adults', ageRange: 'Ages 13 or above' },
        { title: 'Children', ageRange: 'Ages 5–15' },
        { title: 'Children', ageRange: 'Ages 2–5' },
        { title: 'Infants', ageRange: 'Under 2' },
    ];
    const handleOpen = (e) => {
        setFilter(e)
    }
    useEffect(() => {
        function handleClickOutside(event) {
            if (filterGridRef.current && !filterGridRef.current.contains(event.target)) {
                handleOpen('');
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <>
            <div className="search__area">
                <div className='search__home'>
                    {[
                        { id: 'departure', icon: 'departure', label: 'Departure', value: 'Dubai (DXB)' },
                        { id: 'start_date', icon: 'arrival', label: 'Start Date', value: 'May 8th, 2025' },
                        { id: 'end_date', icon: 'arrival', label: 'End Date', value: 'May 8th, 2025' },
                        { id: 'guests', icon: 'guests', label: 'Guests', value: 'Dubai (DXB)' }
                    ].map(({ id, icon, label, value }) => (
                        <div className="grid" key={id} onClick={() => handleOpen(id)}>
                            <div className="title">
                                <div className="icon">
                                    <img src={`assets/img/icons/${icon}.svg`} alt={label} />
                                </div>
                                <h6>{label} <img src="assets/img/icons/dropdown.svg" alt="Dropdown" /></h6>
                            </div>
                            <h4>{value}</h4>
                        </div>
                    ))}
                    <div className="grid">
                        <Link to={'/search'}> <button className='btn btn-primary search'>{!btnTitle ? "Let's Go!" : btnTitle}</button></Link>

                    </div>
                </div>

                {['departure', 'start_date', 'end_date', 'guests'].includes(filter) && (
                    <div className='filterGrid'
                        style={{ left: filter === 'departure' ? '0%' : filter === 'start_date' ? '19%' : filter === 'end_date' ? '37%' : filter === 'guests' ? '56%' : null }}

                        ref={filterGridRef}>
                        {filter === 'departure' ? (
                            <>
                                <h4>Select departure</h4>
                                <Select options={options} placeholder='Departure' components={{ IndicatorSeparator: () => null }} />
                            </>
                        ) : filter === 'start_date' ? (
                            <>
                                <h4>Start Date</h4>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText='Start Date'
                                    inline
                                />
                            </>
                        ) : filter === 'end_date' ? (
                            <>
                                <h4>End Date</h4>
                                <DatePicker
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText='End Date'
                                    inline
                                />
                            </>
                        ) : (
                            <div className='guests'>
                                {items.map((item, index) => (
                                    <CounterItem key={index} title={item.title} ageRange={item.ageRange} />
                                ))}
                            </div>
                        )}
                    </div>
                )}


            </div>


        </>


    )
}
