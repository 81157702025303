import React from 'react'

export default function Title({title,desc,color}) {
  return (
    <div className="main__title" data-aos="fade-up" data-aos-duration="3000">
        <h2 style={{color:color}}>{title}</h2>
        {desc &&  <p style={{color:color}}>{desc}</p>}
       
    </div>
  )
}
