import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import Countdown from 'react-countdown';
import OTPInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { useWizard } from 'rhf-wizard';

export default function Step3() {
  const [otp, setOtp] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const renderer = ({ minutes, seconds }) => {
    return (
        <span>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
    );
};
const handleComplete = () => {
    setIsCompleted(true);
};
  return (
    <div className='container'>
      <div className="flight__tickets">
        <div className="row">
          <div className="col-md-8">
            <div className="review__section">
              <h4>Review your Booking</h4>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="card__header">
                  <h5>Start: Tue, 8 May</h5>
                  <p>Selected Flight</p>
                </div>
                <div className="trip_details">
                  <div className="grid">
                    <div className="logo">
                      <div className="icon">
                        <img src="/assets/img/icons/emirates.svg" alt="" />
                      </div>
                      <div className="icon">
                        <img src="/assets/img/icons/icelandair.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div class="flight-info">
                      <div class="departure">
                        <h4 class="time">23:45</h4>
                        <h5 class="city">Dubai</h5>
                      </div>
                      <div class="flight-details">
                        <span class="duration">21 h 35 m</span>
                        <span class="plane-icon"><img src="/assets/img/icons/aeroplane.svg" alt="" /></span>
                        <span class="dashed-line"></span>
                        <span class="stops">1 stop, CPH</span>
                      </div>
                      <div class="arrival">
                        <h4 class="time">17:20</h4>
                        <h5 class="city">Keflavik</h5>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <p>Departure & Return</p>
                    <span>Emirates airline / Iceland airline</span>
                    <div className="details">Flight Details</div>
                  </div>

                </div>

                <div className="card__header return">
                  <h5>End : Mon, 13 May</h5>
                </div>
                <div className="trip_details">
                  <div className="grid">
                    <div className="logo">
                      <div className="icon">
                        <img src="/assets/img/icons/emirates.svg" alt="" />
                      </div>
                      <div className="icon">
                        <img src="/assets/img/icons/icelandair.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <div class="flight-info">
                      <div class="departure">
                        <h4 class="time">10:40</h4>
                        <h5 class="city">Keflavik</h5>
                      </div>
                      <div class="flight-details">
                        <span class="duration">21 h 35 m</span>
                        <span class="plane-icon return"><img src="/assets/img/icons/aeroplane.svg" alt="" /></span>
                        <span class="dashed-line"></span>
                        <span class="stops">1 stop, CPH</span>
                      </div>
                      <div class="arrival">
                        <h4 class="time">04:25</h4>
                        <h5 class="city">Dubai</h5>
                      </div>
                    </div>
                  </div>
                  <div className="grid">
                    <p>Price per Person</p>
                    <h1>1500 <span>USD</span></h1>
                  </div>

                </div>
              </div>
            </div>
            <div className="review__section">
              <h4 className='mb-1'>Main traveller Details</h4>
              <p>Please provide the details of the main traveler below</p>
            </div>

            <div className="travel__details">
              <div className="card">
                <div className="card-body">
                  {
                    false &&
                    <>
                      <div className="row">
                        <div className="col-md-5">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter first name" />
                          </Form.Group>
                        </div>
                        <div className="col-md-5">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter last name" />
                          </Form.Group>
                        </div>
                        <div className="col-md-5">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter email address" />
                          </Form.Group>
                        </div>
                        <div className="col-md-5">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="Enter phone Number" />
                          </Form.Group>
                        </div>
                        <div className="col-md-5">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Nationality</Form.Label>
                            <Form.Control type="text" placeholder="Select nationality" />
                          </Form.Group>
                        </div>
                        <div className="col-md-5">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Birth Date</Form.Label>
                            <Form.Control type="text" placeholder="Select your date of birth" />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="desclaimer">
                        <p>* We'll send a one-time password (OTP) to this email for verification purposes. This helps us ensure the security of your information.</p>
                        <div className="btn-next">
                          next
                          <img src="/assets/img/icons/button-arrow.svg" alt="" />
                        </div>
                      </div>
                    </>
                  }
                  {
                    false &&
                    <div className="otp_validate">
                      <p>We have send an one-time password (OTP) to <strong>johndoe@nomail.com</strong> email for verification purposes</p>
                      <div className="otp_inputs">
                        <OTPInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          className='otp'
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                      <h6>enter the OTP</h6>
                      {!isCompleted ? (
                        <Countdown
                          date={Date.now() + 30000}
                          zeroPadTime={2}
                          renderer={renderer}
                          onComplete={handleComplete}
                        />
                      ) : (
                        <button className='btn btn-sm btn-resend' onClick={() => alert('Time is up!')}>Resend OTP</button>
                      )}
                      <div className="desclaimer">
                        <div className="btn-next">
                          VERIFY
                          <img src="/assets/img/icons/button-arrow.svg" alt="" />
                        </div>
                      </div>
                    </div>
                  }

                  {
                    false &&
                    <>
                      <div className="otp_validate success__msg">
                        <img src="/assets/img/icons/check-circle.svg" className='check' alt="" />
                        <p><strong>Verification Successful</strong> <br /> The email address you enter will be our primary point of contact for all communication regarding your booking. you can Proceed to the payment</p>
                      </div>
                    </>
                  }

                  {
                    true &&
                    <>
                      <div className="otp_validate p-0 text-left">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="">First Name</label>
                              <input type="text" className='form-control w-100' placeholder='type here' />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="">Last Name</label>
                              <input type="text" className='form-control w-100' placeholder='type here' />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="">Email</label>
                              <input type="text" className='form-control w-100' placeholder='type here' />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="">Phone Number</label>
                              <div className="withIcon">
                                <img src="/assets/img/icons/check-circle.svg" className='icon' alt="" />
                                <input type="text" className='form-control w-100' placeholder='type here' />
                              </div>

                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="">Nationality</label>
                              <input type="text" className='form-control w-100' placeholder='type here' />
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <label htmlFor="">Birth Date</label>
                              <input type="date" className='form-control w-100' placeholder='type here' />
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>


          </div>
          <div className="col-md-4">
            <div className="card pricing_details">
              <div className="card-body">
                <div className="package__details bb">
                  <h4>PACKAGE</h4>
                  <p>2500<span> USD X Per Person</span></p>
                  <span>total of 5000 USD</span>
                </div>
                <div className="package__details bb">
                  <h4>AIRFARE</h4>
                  <p>1500 <span>USD X Per Person</span></p>
                  <span>total of 5000 USD</span>
                </div>
                <div className="discount bb">
                  <h6>Discount</h6>
                  <p>1380 Usd</p>
                </div>
                <div className="details bb">
                  <h4>PACKAGE DETAILS</h4>
                  <div className="item">
                    <h6>Adults</h6>
                    <p>02</p>
                  </div>
                  <div className="item">
                    <h6>Child</h6>
                    <p>01</p>
                  </div>
                  <div className="item">
                    <h6>Duration</h6>
                    <p>10 Days</p>
                  </div>
                </div>
                <div className="grand__total bb">
                  <h4>GRAND TOTAL <img src="/assets/img/icons/total.svg" height={20} alt="" /></h4>
                  <h2>4,000 <span><strong>USD</strong> <small>X Per Person</small></span></h2>
                  <p>total of 10,800 USD <span>( Approx.)</span></p>
                </div>
                <div className="reserve bb">
                  <img src="/assets/img/icons/reserve.svg" alt="" />
                  <div> Reserve now for <br />just <strong>$300</strong> and pay the rest later!</div>
                </div>
                <div className="book__now bb">
                  <div className="price">
                    <h3>300 <small>USD</small></h3>
                  </div>
                  <div className="link">
                    book now
                    <img src="/assets/img/icons/button-arrow.svg" alt="" />
                  </div>
                </div>

              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="promo_code">
                  <h4>PROMOTIONAL code</h4>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Gift card or promo code"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <button className='btn btn-secondary'>Apply</button>
                  </InputGroup>
                </div>
                <div className="footer_links">
                  <Link to='/'>Booking Process</Link>
                  <Link to='/'>Cancellation Policy</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
