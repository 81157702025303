import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import About from '../Pages/About'
import Curated from '../Pages/Curated'
import Blogs from '../Pages/Blogs'
import BlogDetails from '../Pages/BlogDetails'
import SelfDrive from '../Pages/SelfDrive'
import Contact from '../Pages/Contact'
import SearchTours from '../Pages/Search/SearchTours'
import PackagesDetails from '../Pages/packageDetails/PackagesDetails'
import ActivityDetails from '../Pages/ActivityDetails'
import AttractionDetails from '../Pages/AttractionDetails'
import PackageBooking from '../Pages/packageDetails/Bookings/PackageBooking'
import Policy from '../Pages/Policy'
import BookingProcess from '../Pages/BookingProcess'
import ManageBooking from '../Pages/ManageBooking'

function RouteHandler() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/curated" element={<Curated />} />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs-details/:id" element={<BlogDetails />} />
      <Route path="/self-drive" element={<SelfDrive />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/search" element={<SearchTours />} />
      <Route path="/package-details/:id" element={<PackagesDetails />} />
      <Route path="/package-booking" element={<PackageBooking />} />
      <Route path="/activity-details/:id" element={<ActivityDetails />} />
      <Route path="/attraction-details/:id" element={<AttractionDetails />} />
      <Route path="/policy" element={<Policy />} />
      <Route path="/booking-process" element={<BookingProcess />} />
      <Route path="/manage-booking" element={<ManageBooking />} />
    </Routes>
  )
}

export default RouteHandler