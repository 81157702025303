import React from 'react'
import Title from '../components/Title'

export default function BookingProcess() {
  return (
    <>
    <div className='skyward__policy'>
        <img src="assets/img/booking.png" className='w-100' alt="" />
        <div className="detailed__description">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-9">
                <Title title={'BOOKING PROCESS'} color={'var(--primary-color)'} />
            <h5>Browse our curated Iceland travel packages. Find the perfect adventure that suits your interests and budget.</h5>
            <div className="content">
                <h5>Token Payment:</h5>
                <p>Secure your spot with a token payment of USD 300.</p>
                <p>This initiates the personalization process.</p>
                <p>Customer Engagement:</p>
                <p>Our dedicated team will contact you to discuss your preferences and tailor your itinerary.</p>
                <h5>Final Quotation:</h5>
                <p>Receive a personalized quote based on your engagement call.</p>
                <h5>Full Payment:</h5>
                <p>Choose a convenient payment method (one-time or milestone, online or offline).</p>
                <p>Complete your payment to confirm your booking.</p>
                <h5>Booking Confirmation & App Download:</h5>
                <p>Receive a booking confirmation and access your travel details.</p>
                <p>Download our mobile app for easy itinerary management and updates.</p>
                <h5>We Handle the Rest:</h5>
                <p>We'll take care of all the bookings (flights, hotels, activities). </p>
                <p>You can relax and look forward to your Iceland adventure!</p>
            </div>
                </div>
            </div>
        </div>
        </div>

    </div>
</>
  )
}
