import ScrollTop from "./components/ScrollTop";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import RouteHandler from "./router/router";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './pageStyles.scss'
import './responsive.scss'

function App() {



  return (
    <>
      <ScrollTop />
      <Header />
      <RouteHandler />
      <Footer/>
      <ToastContainer autoClose={3000} position='bottom-center' />
    </>
  );
}

export default App;
