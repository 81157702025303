import React from 'react'
import Slider from 'react-slick'
import { activity } from '../components/config'

export default function AttractionDetails() {
    const lists = [
        {
          src: "/assets/img/activities.png",
          title: "Northern Lights Tour",
          desc: "Iceland's vibrant capital, offering culture, nightlife, and stunning views"
        },
        {
          src: "/assets/img/activities.png",
          title: "Reykjavík City Walk",
          desc: "Iceland's vibrant capital, offering culture, nightlife, and stunning views"
        },
        {
          src: "/assets/img/activities.png",
          title: "Northern Lights by Boat",
         desc: "Iceland's vibrant capital, offering culture, nightlife, and stunning views"
        },
        {
          src: "/assets/img/activities.png",
          title: "Whale Watching",
          desc: "Iceland's vibrant capital, offering culture, nightlife, and stunning views"
        },
        {
          src: "/assets/img/activities.png",
          title: "Reykjavík City Walk",
          desc: "Iceland's vibrant capital, offering culture, nightlife, and stunning views"
        }
      ]
  return (
    <div className='section__activities'>
    <img className='main' src="/assets/img/attraction2.png" alt="" />
    <div className="container">
      <div className="tag bg-secondary">Attractions</div>
      <h1>Reykjavík</h1>
      <h6> <img src="/assets/img/icons/clock.svg" alt="" /> Duration 4 hours</h6>
      <h2>Details</h2>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
    </div>
   
      <div className="more_activities">
      <div className="container-fluid p-0">
        <h3>MORE ATTRACTIONS</h3>
        <Slider {...activity}>
          {
            lists?.map((ele, i) => {
              return (
                <div className="gridItem">
                  <img src={ele?.src} alt="" />
                  <h4>{ele?.title}</h4>
                  <h6 className='attraction'>{ele?.desc}</h6>
                </div>
              )
            })
          }
        </Slider>
      </div>
    </div>
  </div>
  )
}
