import React from 'react'
import Slider from 'react-slick'
import { curated } from '../../../components/config'
import StressFree from '../../../components/StressFree'
import { Wizard, useWizard, BasicFooter } from "rhf-wizard";
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Navigation from './Navigation';
import PackageExpect from '../../../components/PackageExpect';

export default function PackageBooking() {


    const steps = [
        {
            id: "0",
            component: <Step2 />,
            hidePrevious: true,
        },
        {
            id: "1",
            component: <Step3 />,
        },
        {
            id: "2",
            component: <Step4 />,
            hideNext: true,
        },
    ];
    return (
        <section className='skyward__package_details p-0'>
            <Slider {...curated}>
                <div className='image_slide'>
                    <img src="/assets/img/details1.jpg" className='w-100 main' alt="" />
                    <div className="container">
                        <div className="col-7">
                            <div className="content">
                                <h1>
                                    RING ROAD CLASSIC –FAST PACED IN 08 DAYS
                                </h1>
                                <div className="btns">
                                    <button className='btn btn-secondary details'>View on map <img src="/assets/img/icons/button-arrow.svg" alt="" /></button>
                                    <button className='btn btn-secondary details'>View Gallery <img src="/assets/img/icons/button-arrow.svg" alt="" /></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='image_slide'>
                    <img src="/assets/img/details2.jpg" className='w-100 main' alt="" />
                </div>
                <div className='image_slide'>
                    <img src="/assets/img/details3.jpg" className='w-100 main' alt="" />
                </div>
            </Slider>
            <div className="booking__wizard">
                <div className="container-fluid p-0">
                    <Wizard
                    header={<Navigation/>}
                        steps={steps}
                        onStepChanged={(fromStep, toStep, wizardValues) => {
                            console.log("step changed", fromStep.id, toStep.id, wizardValues);
                        }}
                        onCompleted={(values) => {
                            alert("wizard completed");
                        }}
                        
                    />
                </div>
            </div>


            <PackageExpect/>

        </section>
    )
}
