import React from 'react'
import Slider from 'react-slick'
import { gallery, hotel_images, partners_opt, rating_review } from '../../components/config'

export default function HotelExperience() {
    const partners = [
        '/assets/img/partners/partner1.png',
        '/assets/img/partners/partner2.png',
        '/assets/img/partners/partner3.png',
        '/assets/img/partners/partner4.png',
        '/assets/img/partners/partner5.png',
        '/assets/img/partners/partner6.png'
    ]
    const gallery_items = [
        ['/assets/img/blog4.png'],
        [
            '/assets/img/blog4.png',
            '/assets/img/blog4.png'
        ],
        ['/assets/img/blog3.png'],
        [
            '/assets/img/blog6.png',
            '/assets/img/blog5.png',
        ],
        ['/assets/img/blog1.png'],
        [
            '/assets/img/blog1.png',
            '/assets/img/blog2.png'
        ],
        ['/assets/img/blog3.png'],
        [
            '/assets/img/blog6.png',
            '/assets/img/blog5.png'
        ],
        ['/assets/img/blog1.png']
    ]
    const images = [
        '/assets/img/attraction1.png',
        '/assets/img/attraction2.png',
        '/assets/img/attraction3.png',
        '/assets/img/attraction4.png',
        '/assets/img/attraction5.png',
    ]
    const reviews = [
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "john doe",
            author_designation: "Customer",
        },
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "Alex",
            author_designation: "Customer",
        },
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "john doe",
            author_designation: "Customer",
        },
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "Jane doe",
            author_designation: "Customer",
        },
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "Ahmed",
            author_designation: "Customer",
        },
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "john doe",
            author_designation: "Customer",
        },
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "john doe",
            author_designation: "Customer",
        },
        {
            title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
            author_name: "john doe",
            author_designation: "Customer",
        }
    ]
    return (
        <>
            <div className='hotel_experience'>
                <div className="container">
                    <h3>HOTEL EXPERIENCE</h3>
                    <h2 className='title'>LUXURY AT YOUR FINGERTIPS</h2>
                    <div className="row align-items-center justify-content-center mt-5 mb-5">
                        <div className="col-md-5 col-tab-2">
                            <img src="/assets/img/experience.png" alt="" className="main" />
                        </div>
                        <div className="col-md-5 col-tab-2">
                            <ul>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> Vivamus eget lorem egestas, auctor ipsum quis, semper elit. </li>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> Sed at ex laoreet erat ornare placerat. Nam gravida, metus</li>
                                <li><img src="/assets/img/icons/check.svg" alt="" />  congue sagittis vestibulum, tellus purus scelerisque nulla, sit</li>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> amet varius dui tortor et tortor. Cras dictum volutpat lectus</li>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem,</li>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis</li>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> efficitur efficitur, enim risus dignissim nisl</li>
                                <li><img src="/assets/img/icons/check.svg" alt="" /> ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit</li>
                            </ul>
                        </div>
                    </div>
                    <div className="partners_grid">
                        <h3>OUR PARTNERS</h3>
                        <Slider {...partners_opt}>
                            {partners.map((ele, index) => (
                                <div className='partners_lists' key={index}>
                                    <img src={ele} alt="" />
                                </div>
                            ))}
                        </Slider>
                    </div>

                </div>
                <div className="container-fluid">
                    <div className="hotel_images">
                        <Slider {...hotel_images}>
                            {images.map((ele, index) => (
                                <div key={index}>
                                    <img src={ele} className='w-100' alt="" />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="container-fluid p-0">
                <div className="rating__review">

                    <div className="container">
                        <h2 className='title'>RATING & REVIEW</h2>
                        <Slider {...rating_review}>
                            {reviews.map((ele, index) => (
                                <div className='review_grid' key={index}>
                                    <div className="content">
                                        <div className="quote"><img src="/assets/img/icons/quote.svg" alt="" /></div>
                                        <div className="content">
                                            <h5>{ele?.title}</h5>
                                        </div>
                                    </div>
                                    <div className="footer">
                                        <ul>
                                            <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                            <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                            <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                            <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                            <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                        </ul>
                                        <div className="name">
                                            <img src="/assets/img/avatar3.png" alt="" />
                                            <div>
                                                <h4>{ele?.author_name}</h4>
                                                <p>{ele?.author_designation}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                    <div className="button_center">
                        <button className='btn btn-primary'>SHOW MORE REVIEW <img src="/assets/img/icons/button-arrow.svg" alt="" /></button>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="gallery">
                    <div className="header">
                        <h2 className='title'>gallery</h2>
                        <div className="view">
                            View All
                            <img src="/assets/img/icons/btn-arrow.svg" alt="" />
                        </div>
                    </div>
                    <Slider {...gallery}>
                        {gallery_items.map((ele, index) => (
                            <div className='gallery_grid' key={index}>
                                {
                                    ele.map(item => {
                                        return (
                                            <div className={`${index % 2 === 0 ? "singleCloumn" : ""} Items`}>
                                                <div className="play"><img src="/assets/img/icons/play.svg" alt="" /></div>
                                                <img src={item} alt="" />
                                            </div>

                                        )
                                    })
                                }
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>

    )
}
