import React, { useState } from 'react'
import Countdown from 'react-countdown'
import DatePicker from 'react-datepicker'
import OTPInput from 'react-otp-input'

export default function ManageBooking() {
    const [startDate, setStartDate] = useState()
    const [isCompleted, setIsCompleted] = useState(false);
    const [otp, setOtp] = useState('');

    const renderer = ({ minutes, seconds }) => {
        return (
            <span>
                {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </span>
        );
    };
    const handleComplete = () => {
        setIsCompleted(true);
    };
    return (
        <div className='manage__booking'>
            <img className='main' src="/assets/img/manage-booking.png" alt="" />
            <div className="container">
                <div className="card">
                    <div className="card-body">
                        <h1>MANAGE booking</h1>
                        {
                            true &&
                            <>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque</p>
                                <div className="row">
                                    <div className="col-md-4">
                                        <input type="text" className="form-control" placeholder='Booking reference ID' />
                                        <p className='eg'>Eg: 534332132</p>
                                    </div>
                                    <div className="col-md-4">
                                        <input type="text" className="form-control" placeholder='Last Name*' />
                                    </div>
                                    <div className="col-md-4">
                                        <div className="icon">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                className='form-control'
                                                // endDate={endDate}
                                                placeholderText='Date of birth'

                                            />
                                            <img src="/assets/img/icons/manage-booking-calendar.svg" className='calendar' alt="" />
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            false &&
                            <>
                                <h5>We have send an one-time password (OTP) to your registered email for verification purposes</h5>
                                <div className="otp_inputs">
                                    <OTPInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={4}
                                        className='otp'
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    <h6>enter the OTP</h6>
                                    {!isCompleted ? (
                                        <Countdown
                                            date={Date.now() + 30000}
                                            zeroPadTime={2}
                                            renderer={renderer}
                                            onComplete={handleComplete}
                                        />
                                    ) : (
                                        <button className='btn btn-sm btn-resend' onClick={() => alert('Time is up!')}>Resend OTP</button>
                                    )}
                                </div>
                            </>
                        }
                        <div className="devider"></div>
                        <div className="submit">
                            Submit
                            <img src="/assets/img/icons/button-arrow.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
