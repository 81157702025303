import React from 'react'
import HomeSearch from '../components/HomeSearch'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function SelfDrive() {
  const popular = [
    {
      img: "assets/img/package1.png",
      tag: "self drive",
      type: "all",
      title: "South Iceland In 8 Days",
      price: "10000",
    },
    {
      img: "assets/img/package2.png",
      tag: "self drive",
      type: "winter",
      title: "Ring Road Classic – Fast Paced In 14 Days",
      price: "10000",
    },
    {
      img: "assets/img/package3.png",
      tag: "self drive",
      type: "summer",
      title: "Full Icelandic Experience In 21 Days",
      price: "10000",
    },
    {
      img: "assets/img/package4.png",
      tag: "self drive",
      type: "winter",
      title: "Waterfalls & Wonders Of Iceland",
      price: "10000",
    },
    {
      img: "assets/img/package1.png",
      tag: "self drive",
      type: "all",
      title: "South Iceland In 8 Days",
      price: "10000",
    },
    {
      img: "assets/img/package2.png",
      tag: "self drive",
      type: "winter",
      title: "Ring Road Classic – Fast Paced In 14 Days",
      price: "10000",
    },
    {
      img: "assets/img/package3.png",
      tag: "self drive",
      type: "summer",
      title: "Full Icelandic Experience In 21 Days",
      price: "10000",
    },
    {
      img: "assets/img/package4.png",
      tag: "self drive",
      type: "winter",
      title: "Waterfalls & Wonders Of Iceland",
      price: "10000",
    }
  ]
  return (
    <>
      <section className='skyward__selfDrive pt-0'>
        <img src="/assets/img/self-drive.jpg" className='w-100 main' alt="" />
        <div className="search__contents">
          <h1>Slef-drive tours</h1>
          <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde eius vel facilis nam perspiciatis id dolorum in, doloribus dolore? Iste molestiae veniam quis. Numquam libero earum cum.</p>
          <HomeSearch />
        </div>
      </section>
      <section className="self-drive__lists">
        <div className="container">
        <div className="row">
            {
              popular?.map((ele, i) => {
                return (
                  <div className="col-md-3 col-tab-2 p-2" key={i}>
                    <div className="package">
                      <div className="img">
                        <div className="type">
                          {
                            ele?.type === 'all' ? <img src='/assets/img/icons/all-year.svg' alt="All Year Icon" /> :
                              ele?.type === 'winter' ? <img src='/assets/img/icons/winter.svg' alt="Winter Icon" /> :
                                ele?.type === 'summer' ? <img src='/assets/img/icons/summer.svg' alt="Summer Icon" /> :
                                  null
                          }
                        </div>
                        <img src={ele?.img} alt="" />
                        <div className="tag">{ele?.tag}</div>
                      </div>
                      <div className="rating">
                        <ul>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                          <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                        </ul>
                        <span>5/5 rating</span>
                      </div>
                      <div className="days">08 days</div>
                      <h2>{ele?.title}</h2>
                      <div className="pricing">
                        <span>From</span>
                        <div className="price">
                          <h3>10000</h3>
                          <Form.Select aria-label="currency">
                            <option value="1">USD</option>
                            <option value="2">AED</option>
                            <option value="3">INR</option>
                          </Form.Select>
                        </div>
                      </div>
                      <div className="view_trip">
                        <Link to='/'>view trip <img src="/assets/img/icons/btn-arrow.svg" alt="" /></Link>
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </div>
          <div className="button_center">
            <button className='btn btn-primary'>VIEW ALL <img src="assets/img/icons/button-arrow.svg" alt="" /></button>
          </div>
        </div>
      </section>
    </>
  )
}
