import React from 'react'
import { useWizard } from 'rhf-wizard';

export default function Step4() {
  const { goToPreviousStep, goToStep } = useWizard();
  const next = [
    {
      img: "/assets/img/icons/start1.svg",
      title: "We're Crafting Your Dream Itinerary",
      desc: "Our team will reach out soon to discuss your travel preferences and create a personalized plan just for you."
    },
    {
      img: "/assets/img/icons/start2.svg",
      title: "Manage Your Booking",
      desc: "In the meantime, you can log in to the website using your token reference number to view and manage your booking details."
    },
    {
      img: "/assets/img/icons/start3.svg",
      title: "Personalized Quote",
      desc: "Once your itinerary is finalized, we'll send you a detailed quote for your review and confirmation."
    },
    {
      img: "/assets/img/icons/start4.svg",
      title: "Explore with Our App",
      desc: "Upon booking confirmation, gain access to our mobile app your one-stop shop for all your travel documents and essential information"
    }
  ]
  return (
    <div className='payment__success'>
      <div className="container">
        <div className="card">
          <div className="card-body">
            <div className="container">

              <div className="row align-items-center">
                <div className="col-md-5">
                  <img src="/assets/img/payment-success.png" alt="" className="main w-100" />
                </div>
                <div className="col-md-7">
                  <div className="message">
                    <h2>Payment Successful!</h2>
                    <p>Your payment of <span>300 USD</span> was successful! You will recieve an email confirming your reservation details shortly!</p>
                    <div className="booking">
                      <div className="bookingID">Booking ID: 3223243231</div>
                      <p>20 March 2024 | 11:11 am</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment__details">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 rightBorder">
                    <h4>Token Payment Details</h4>
                    <div className="detailGrid">
                      <div className="item">
                        <h6>Token Advance <span>300 USD</span></h6>
                        <h6>VAT 5% <span>15</span></h6>
                        <h6>Total Paid <span>315 USD</span></h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h4>Package Details</h4>
                    <div className="detailGrid">
                      <div className="item">
                        <h5>Package Name <span>Ring Road Classic –Fast paced in 08 days</span></h5>
                        <h5>Duration <span>08 Days</span></h5>
                        <h5>Total Number of traveller <span>03</span></h5>
                        <h5>Adult <span>02</span></h5>
                        <h5>Adult <span>01</span></h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment__details">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 rightBorder p-0">
                    <h4>Main Traveller Details</h4>
                    <div className="detailGrid gridInline">
                      <div className="item">
                        <h6 className='mb-1'>First Name</h6>
                        <span>300 USD</span>
                      </div>
                      <div className="item">
                        <h6 className='mb-1'>Last Name</h6>
                        <span>Doe</span>
                      </div>
                      <div className="item">
                        <h6 className='mb-1'>Email</h6>
                        <span>johndoe@nomail.com</span>
                      </div>
                      <div className="item">
                        <h6 className='mb-1'>Phone Number</h6>
                        <span>+971 444 333 322</span>
                      </div>
                      <div className="item">
                        <h6 className='mb-1'>Nationality</h6>
                        <span>Indian</span>
                      </div>
                      <div className="item">
                        <h6 className='mb-1'>Birth Date</h6>
                        <span>25 march 1990</span>
                      </div>
                    </div>
                    <div className="anticipated__cost">
                      <div className="item">
                        <h2>ANTICIPATED COST</h2>
                        <h3>5,000 <span>USD X Per Person</span></h3>
                        <p>total of 10,800 USD</p>
                      </div>
                    </div>
                    <div className="login">
                      <p>Please login to manage the Additional Details</p>
                      <button className="btn-login">login</button>
                    </div>
                  </div>
                  <div className="col-md-6 position-relative">
                    <h4>Flght Details</h4>
                    <div className="detailGrid">
                      <div className="item">
                        <h5>Chosen Flights <span>Emirates & Icelenad Air</span></h5>
                        <h5>Point of Origin <span>Dubai</span></h5>
                        <h5>Travelling to <span>Keflavik</span></h5>
                      </div>
                    </div>
                    <div className="text-right">
                      <img src="/assets/img/flight-img.png" className='flight' alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="card">
          <div className="card-body">
            <div className="what__next">
              <h3>Whats Next?</h3>
              <div className="row">
                {
                  next?.map((ele, i) => {
                    return (
                      <div className="col-md-3" key={i}>
                        <div className="nextGrid">
                          <img src={ele?.img} alt="" />
                          <h2>{ele?.title}</h2>
                          <p>{ele?.desc}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
