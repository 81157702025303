export const choose_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false
        }
      }
    ]
  };
  export const testimonial_settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  export const curated = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  export const curated_gallery = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: true,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
  };
  
  export const recommended_tours = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
  }

  export const vehicle_exp = {
    dots: false, // Shows navigation dots
    infinite: false, // Allows infinite scrolling
    speed: 500, // Transition speed in milliseconds
    rows: 2, // Number of rows
    arrows: true,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, 
          slidesPerRow: 3,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, 
          slidesPerRow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, 
          slidesPerRow: 2,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1, 
          slidesPerRow: 3,
        }
      }
    ]
  };

  export const partners_opt = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows : false,
    arrows: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
          dots:false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
        }
      }
    ]
  };

  export const hotel_images = {
    dots: false,
    infinite: false,
    speed: 500,
    swipeToSlide: true,
    arrows: true,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3.5, 
          slidesToScroll: 1.5,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3.5, 
          slidesToScroll: 1.5,
        }
      }
    ]
  }

  export const rating_review = {
    dots: false,
    infinite: false, 
    speed: 500, 
    arrows: false,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
          rows: 2,
          slidesPerRow: 3,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: false,
          rows: 1,
          slidesPerRow: 3,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          rows: 1,
          slidesPerRow: 3,
        }
      }
    ]
  }
  export const gallery = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <img src="/assets/img/icons/right-arrow.svg" />,
    prevArrow: <img src="/assets/img/icons/left-arrow.svg" />,
  }
  export const activity= {
    dots: false,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          speed: 3000,
          autoplaySpeed: 3000,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: false,
          speed: 5000,
          autoplaySpeed: 5000,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
          dots: false,
          speed: 5000,
          autoplaySpeed: 5000,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          rows: 1,
          slidesPerRow: 3,
          speed: 5000,
          autoplaySpeed: 5000,
        }
      }
    ]
  }