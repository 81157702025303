import React from 'react'
import HomeSearch from '../../components/HomeSearch'
import { recommended_tours } from '../../components/config'
import Slider from 'react-slick'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function SearchTours() {
    const tours_packages = [
        {
            title: "Ring Road Classic – Fast Paced In 08 Days",
            img: "/assets/img/drive1.png"
        },
        {
            title: "Ring Road Classic – Fast Paced In 08 Days",
            img: "/assets/img/drive2.png"
        },
        {
            title: "Ring Road Classic – Fast Paced In 08 Days",
            img: "/assets/img/drive3.png"
        }
    ]

    const popular = [
        {
            img: "assets/img/package1.png",
            tag: "self drive",
            type: "all",
            title: "South Iceland In 8 Days",
            price: "10000",
        },
        {
            img: "assets/img/package2.png",
            tag: "self drive",
            type: "winter",
            title: "Ring Road Classic – Fast Paced In 14 Days",
            price: "10000",
        },
        {
            img: "assets/img/package3.png",
            tag: "self drive",
            type: "summer",
            title: "Full Icelandic Experience In 21 Days",
            price: "10000",
        },
        {
            img: "assets/img/package4.png",
            tag: "self drive",
            type: "winter",
            title: "Waterfalls & Wonders Of Iceland",
            price: "10000",
        },
    ]
    return (
        <>
            <section className='skyward__selfDrive pt-0'>
                <img src="/assets/img/self-drive.jpg" className='w-100 main' alt="" />
                <div className="search__contents">
                    <h1>Slef-drive tours</h1>
                    <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Unde eius vel facilis nam perspiciatis id dolorum in, doloribus dolore? Iste molestiae veniam quis. Numquam libero earum cum.</p>
                    <HomeSearch />
                </div>
            </section>
            <div className="refined_search">
                <div className="container">
                    <h2>We've Refined Your Search Results. Explore these iceland packages that fir your specific needs</h2>
                </div>
            </div>
            <section className="recommended__packages">
                <div className="container">
                    <h2>RECOMMENDED packages</h2>
                </div>
                <div className="tour_packages scroll">
                    {
                        tours_packages?.map((ele, i) => {
                            return (
                                <div className="trips" key={i}>
                                    <div className="image">
                                        <img src={ele?.img} className='w-100' alt="" />
                                        <div className="tag">HIGHLY recommended</div>
                                    </div>
                                    <div className="details">
                                        <h3>{ele?.title}</h3>
                                        <div className="package_features">
                                            <div className="lists">
                                                <h3>5/5</h3>
                                                <h6>rating</h6>
                                            </div>
                                            <div className="lists">
                                                <ul>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                </ul>
                                                <h6>( 140 REVIEW )</h6>
                                            </div>
                                            <div className="lists">
                                                <div className="icon"><img src="assets/img/icons/drive.svg" alt="" /></div>
                                                <h6>Self drive</h6>
                                            </div>
                                            <div className="lists">
                                                <h3>08</h3>
                                                <h6>Days</h6>
                                            </div>
                                            <div className="lists">
                                                <div className="icon"><img src="assets/img/icons/winter-tour.svg" alt="" /></div>
                                                <h6>Winter</h6>
                                            </div>
                                            <div className="lists">
                                                <div className="icon"><img src="assets/img/icons/date-from.svg" alt="" /></div>
                                                <h6>APR-OCT</h6>
                                            </div>
                                            <div className="lists">
                                                <div className="icon"><img src="assets/img/icons/km.svg" alt="" /></div>
                                                <h6>2110/ 1144 km/mi</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 pe-0">
                                                <div className="price_details">
                                                    <ul>
                                                        <li><img src="/assets/img/icons/hotel.svg" alt="" /> Premier Hotel Stays including Breakfasts</li>
                                                        <li><img src="/assets/img/icons/car-rent.svg" alt="" /> Car-Rentals </li>
                                                        <li><img src="/assets/img/icons/entertainment.svg" alt="" /> Entertainment activities including spa</li>
                                                        <li><img src="/assets/img/icons/insurance.svg" alt="" /> Car & Travel Insurance</li>
                                                        <li><img src="/assets/img/icons/customise.svg" alt="" /> Engagement session to customise travel experience.</li>
                                                        <li><img src="/assets/img/icons/support.svg" alt="" /> Live support during Travel</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-6 ps-0">
                                                <div className="price_details br-0">
                                                    <h6>Price per person</h6>
                                                    <h5>From</h5>
                                                    <h2>2,500 <span>USD</span></h2>
                                                    <h3>total of 5,000 USD</h3>
                                                    <p>Exclusive of flight cost</p>
                                                    <div className="reserve">
                                                        <img src="/assets/img/icons/reserve.svg" alt="" />
                                                        Reserve now for just $300 and pay the rest later!
                                                    </div>
                                                </div>
                                                <Link to={`/package-details/1`}>
                                                <div className="view_trip">
                                                    view trip
                                                    <img src="/assets/img/icons/view-tour-arrow.svg" alt="" />

                                                </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                    }


                </div>

            </section>
            <section className="explore__packages">
                <div className="container">
                    <h2>EXPLORE PACKAGES</h2>
                    <div className="row">
                        {
                            popular?.map((ele, i) => {
                                return (
                                    <div className="col-md-3 p-2 col-tab-2" key={i}>
                                        <div className="package">
                                            <div className="img">
                                                <div className="type">
                                                    {
                                                        ele?.type === 'all' ? <img src='/assets/img/icons/all-year.svg' alt="All Year Icon" /> :
                                                            ele?.type === 'winter' ? <img src='/assets/img/icons/winter.svg' alt="Winter Icon" /> :
                                                                ele?.type === 'summer' ? <img src='/assets/img/icons/summer.svg' alt="Summer Icon" /> :
                                                                    null
                                                    }
                                                </div>
                                                <img src={ele?.img} alt="" />
                                                <div className="tag">{ele?.tag}</div>
                                            </div>
                                            <div className="rating">
                                                <ul>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                    <li><img src="assets/img/icons/rate1.svg" alt="" /></li>
                                                </ul>
                                                <span>5/5 rating</span>
                                            </div>
                                            <div className="days">08 days</div>
                                            <h2>{ele?.title}</h2>
                                            <div className="pricing">
                                                <span>From</span>
                                                <div className="price">
                                                    <h3>10000</h3>
                                                    <Form.Select aria-label="currency">
                                                        <option value="1">USD</option>
                                                        <option value="2">AED</option>
                                                        <option value="3">INR</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className="view_trip">
                                                <Link to='/'>view trip <img src="/assets/img/icons/btn-arrow.svg" alt="" /></Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}
