import React from 'react'
import { Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function BlogDetails() {
    const popular = [
        {
            img: "/assets/img/package1.png",
            tag: "self drive",
            type: "all",
            title: "South Iceland In 8 Days",
            price: "10000",
        },
        {
            img: "/assets/img/package2.png",
            tag: "self drive",
            type: "winter",
            title: "Ring Road Classic – Fast Paced In 14 Days",
            price: "10000",
        },
        {
            img: "/assets/img/package3.png",
            tag: "self drive",
            type: "summer",
            title: "Full Icelandic Experience In 21 Days",
            price: "10000",
        },
        {
            img: "/assets/img/package4.png",
            tag: "self drive",
            type: "winter",
            title: "Waterfalls & Wonders Of Iceland",
            price: "10000",
        }
    ]
    return (
        <section className='blog__details pt-0'>
            <img src="/assets/img/blog-details.png" className='w-100 main' alt="" />
            <div className="container">
                <h1>Self-Drive Tour Of The Complete Ring Road Of Iceland With Top Attractions & Snaefellsnes</h1>
                <div className="share">
                    <p>02 Auguest 2024</p>
                    <div className="socials">
                        <p>Share</p>
                        <ul>
                            <li><img src="/assets/img/icons/fb-share.svg" alt="" /></li>
                            <li><img src="/assets/img/icons/x-share.svg" alt="" /></li>
                            <li><img src="/assets/img/icons/instagram-share.svg" alt="" /></li>
                        </ul>
                    </div>
                </div>
                <div className="details">
                    <p className='detail-para'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus eget lorem egestas, auctor ipsum quis, semper elit. Sed at ex laoreet erat ornare placerat. Nam gravida, metus congue sagittis vestibulum, tellus purus scelerisque nulla, sit amet varius dui tortor et tortor. Cras dictum volutpat lectus, sed aliquet nulla luctus id. Maecenas porttitor consectetur lorem, consectetur pretium mauris hendrerit ut. Duis aliquam, quam quis efficitur efficitur, enim risus dignissim nisl, ut dapibus nunc turpis consectetur erat. Sed ultrices ante sit amet condimentum consectetur. Pellentesque justo velit, commodo non nisl ac, volutpat tristique sem. Pellentesque eget libero sollicitudin, pharetra lectus eu, varius sem. Suspendisse in mauris ac nisi efficitur auctor nec vel diam. Vivamus eleifend accumsan ante, sit amet molestie nisi.</p>
                    <div className='row blog__images'>
                        <div className="col-6 video">
                            <img className='video-bg' src="/assets/img/blog-detail3.png" alt="" />
                            <div className="play"><img src="/assets/img/icons/play.svg" alt="" /></div>
                        </div>
                        <div className="col-6">
                            <div className="image">
                                <img src="/assets/img/blog-detail2.png" alt="" />
                            </div>
                            <div className="image">
                                <img src="/assets/img/blog-detail1.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <p className='detail-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita suscipit porro reiciendis a aut quo velit. Nihil libero facilis dolore adipisci fugit eveniet totam nostrum rem quam, rerum eius recusandae?
                        Magnam corporis totam ipsum sapiente magni aliquid quas nulla sed! Eum dolorum ipsum cumque doloremque veritatis vero unde eius, alias ab mollitia aliquam officiis nulla ad, saepe deleniti excepturi. Corrupti?
                        Facere explicabo hic a molestiae? Cum nobis eius vel deleniti doloremque soluta at quis, ea placeat distinctio possimus totam. Optio inventore architecto odit deleniti, vero quae dolore dicta culpa explicabo.
                        Excepturi corporis maxime vero accusantium amet id expedita? Ducimus a soluta molestiae incidunt! Voluptates quo corrupti, optio minus earum eaque magni. Tempora laudantium harum facilis ipsum nam suscipit, fugiat error?
                        Voluptas dolor sunt odio facilis amet, consectetur quisquam dicta adipisci animi expedita quis similique provident odit, delectus eligendi voluptatem? Possimus natus ducimus quo sint eius pariatur tempora totam officiis incidunt!
                        Tenetur rerum maxime voluptates at, in nihil et eos, similique, quos soluta veniam repudiandae sapiente rem voluptatum porro vel unde odio? Rem esse ad similique pariatur, tempore reiciendis minus saepe.
                        Iure quaerat obcaecati neque aliquid id sed. Sunt nam nulla cum suscipit vitae eaque atque officiis, et nisi exercitationem, vero porro architecto, fuga cumque ratione dignissimos ex distinctio itaque rem?
                        Fugiat quibusdam deserunt error quia, laudantium ipsa omnis similique ad. Dicta, adipisci sunt consectetur distinctio ipsa, possimus error fugit exercitationem fugiat sint, delectus minus sit. Ipsa rerum eveniet magnam maxime.</p>
                </div>

                <div className="row">

                    <h4 className='associated'>ASSOCIATED PACKAGES</h4>
                    {
                        popular?.map((ele, i) => {
                            return (
                                <div className="col-md-3 col-tab-6 p-2" key={i}>
                                    <div className="package">
                                        <div className="img">
                                            <div className="type">
                                                {
                                                    ele?.type === 'all' ? <img src='/assets/img/icons/all-year.svg' alt="All Year Icon" /> :
                                                        ele?.type === 'winter' ? <img src='/assets/img/icons/winter.svg' alt="Winter Icon" /> :
                                                            ele?.type === 'summer' ? <img src='/assets/img/icons/summer.svg' alt="Summer Icon" /> :
                                                                null
                                                }
                                            </div>
                                            <img src={ele?.img} alt="" />
                                            <div className="tag">{ele?.tag}</div>
                                        </div>
                                        <div className="rating">
                                            <ul>
                                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                                <li><img src="/assets/img/icons/rate1.svg" alt="" /></li>
                                            </ul>
                                            <span>5/5 rating</span>
                                        </div>
                                        <div className="days">08 days</div>
                                        <h2>{ele?.title}</h2>
                                        <div className="pricing">
                                            <span>From</span>
                                            <div className="price">
                                                <h3>10000</h3>
                                                <Form.Select aria-label="currency">
                                                    <option value="1">USD</option>
                                                    <option value="2">AED</option>
                                                    <option value="3">INR</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="view_trip">
                                            <Link to='/'>view trip <img src="/assets/img/icons/btn-arrow.svg" alt="" /></Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
        </section>
    )
}
