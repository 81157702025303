import React from 'react'
import { Form, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useWizard } from 'rhf-wizard';

export default function Step2() {
  const lists = [1, 2, 3, 4]
  return (
    <div className='container'>
      <div className="flight__tickets">
        <div className="row">
          <div className="col-md-8">
            {
              lists?.map((ele, i) => {
                return (
                  <div className="card" key={i}>
                    <div className="card-body">
                      <div className="card__header">
                        <h5>Start: Tue, 8 May</h5>
                        <p>Recommended</p>
                      </div>
                      <div className="trip_details">
                        <div className="grid">
                          <div className="logo">
                            <div className="icon">
                              <img src="/assets/img/icons/emirates.svg" alt="" />
                            </div>
                            <div className="icon">
                              <img src="/assets/img/icons/icelandair.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <div class="flight-info">
                            <div class="departure">
                              <h4 class="time">23:45</h4>
                              <h5 class="city">Dubai</h5>
                            </div>
                            <div class="flight-details">
                              <span class="duration">21 h 35 m</span>
                              <span class="plane-icon"><img src="/assets/img/icons/aeroplane.svg" alt="" /></span>
                              <span class="dashed-line"></span>
                              <span class="stops">1 stop, CPH</span>
                            </div>
                            <div class="arrival">
                              <h4 class="time">17:20</h4>
                              <h5 class="city">Keflavik</h5>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <p>Departure & Return</p>
                          <span>Emirates airline / Iceland airline</span>
                          <div className="details">Flight Details</div>
                        </div>

                      </div>

                      <div className="card__header return">
                        <h5>End : Mon, 13 May</h5>
                      </div>
                      <div className="trip_details">
                        <div className="grid">
                          <div className="logo">
                            <div className="icon">
                              <img src="/assets/img/icons/emirates.svg" alt="" />
                            </div>
                            <div className="icon">
                              <img src="/assets/img/icons/icelandair.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <div class="flight-info">
                            <div class="departure">
                              <h4 class="time">10:40</h4>
                              <h5 class="city">Keflavik</h5>
                            </div>
                            <div class="flight-details">
                              <span class="duration">21 h 35 m</span>
                              <span class="plane-icon return"><img src="/assets/img/icons/aeroplane.svg" alt="" /></span>
                              <span class="dashed-line"></span>
                              <span class="stops">1 stop, CPH</span>
                            </div>
                            <div class="arrival">
                              <h4 class="time">04:25</h4>
                              <h5 class="city">Dubai</h5>
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <p>Price per Person</p>
                          <h1>1500 <span>USD</span></h1>
                          <div className="select_flight">
                            Select Flight
                            <img src="/assets/img/icons/button-arrow.svg" alt="" />
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                )
              })
            }

            <div className="view_more">
              view more
              <img src="/assets/img/icons/btn-arrow-dark.svg" alt="" />
            </div>

          </div>
          <div className="col-md-4">
            <div className="card pricing_details">
              <div className="card-body">
                <div className="package__details bb">
                  <h4>PACKAGE</h4>
                  <p>2500<span> USD X Per Person</span></p>
                  <span>total of 5000 USD</span>
                </div>
                <div className="package__details bb">
                  <h4>AIRFARE</h4>
                  <p>1500 <span>USD X Per Person</span></p>
                  <span>total of 5000 USD</span>
                </div>
                <div className="discount bb">
                  <h6>Discount</h6>
                  <p>1380 Usd</p>
                </div>
                <div className="details bb">
                  <h4>PACKAGE DETAILS</h4>
                  <div className="item">
                    <h6>Adults</h6>
                    <p>02</p>
                  </div>
                  <div className="item">
                    <h6>Child</h6>
                    <p>01</p>
                  </div>
                  <div className="item">
                    <h6>Duration</h6>
                    <p>10 Days</p>
                  </div>
                </div>
                <div className="grand__total bb">
                  <h4>GRAND TOTAL <img src="/assets/img/icons/total.svg" height={20} alt="" /></h4>
                  <h2>4,000 <span><strong>USD</strong> <small>X Per Person</small></span></h2>
                  <p>total of 10,800 USD <span>( Approx.)</span></p>
                </div>
                <div className="reserve bb">
                  <img src="/assets/img/icons/reserve.svg" alt="" />
                  <div> Reserve now for <br />just <strong>$300</strong> and pay the rest later!</div>
                </div>
                <div className="book__now bb">
                  <div className="price">
                    <h3>300 <small>USD</small></h3>
                  </div>
                  <div className="link">
                    book now
                    <img src="/assets/img/icons/button-arrow.svg" alt="" />
                  </div>
                </div>

              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="promo_code">
                  <h4>PROMOTIONAL code</h4>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Gift card or promo code"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                    <button className='btn btn-secondary'>Apply</button>
                  </InputGroup>
                </div>
                <div className="footer_links">
                    <Link to='/'>Booking Process</Link>
                    <Link to='/'>Cancellation Policy</Link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
